import React, {useEffect} from 'react'
import cx from "classnames";
import getYouTubeID from "get-youtube-id";
import {delayMultiplicationFactor} from "../../utils/articleUtil";
import {isMobile} from "react-device-detect";

const EmbedYoutube = (props) => {
    const className = cx(
        "youtube-videoWrapper",
        props.className
    );

    const videoId = props.videoId ? props.videoId : getYouTubeID(props.video);

    /*const youtubeVideoDisplay = () => {
        const vidDefer = document.getElementsByClassName('pt-news-iframe')[0];
        if (vidDefer.getAttribute('data-src')) {
            vidDefer.setAttribute('src', vidDefer.getAttribute('data-src'));
            vidDefer.removeAttribute('data-src');
        }
    }

    useEffect(() => {
        setTimeout(() => {
            youtubeVideoDisplay();
        }, 1000 * delayMultiplicationFactor(isMobile))
    }, []);*/

    return <div className={className} style={props.style}>
        <iframe className={"pt-news-iframe"} width="560" height="315" data-src={`https://www.youtube.com/embed/${videoId}`} title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen/>
    </div>
}

export default EmbedYoutube;